<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-book"/>
                  Freizugebene Leads
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                    :items="tableData"
                    :itemsPerPageSelect="{ label: 'Leads pro Seite' }"
                    :fields="getTableFields()"
                    :noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar',
                    noItems: 'Keine Leads in der Freigabe' }"
                    hover
                    striped
                    bordered
                    fixed
                    column-filter
                    table-filter
                    :tableFilter="{ label: 'Leads suchen', placeholder: 'Suchbegriff eingeben' }"
                    items-per-page-select
                    sorter
                    :sorterValue="{ column: 'updated', asc: false }"
                    pagination
                    :loading="tableShowLoading"
            >
              <template #type="{item}">
                <td>
                  <CBadge :color="getTableBadge(item.type)">
                    {{item.type}}
                  </CBadge>
                </td>
              </template>
              <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                          :to="'queuedLeads/edit/' + item.id"
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                  >
                    Edit
                  </CButton>
                </td>
              </template>
              <template #created="{ item }">
                <td>
                  {{ $moment(item.created).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style scoped>
  .prt-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .prt-card-header > div {
    text-align: right;
  }
</style>
<script>
  import axios from 'axios'
  import commons from "../../commons"

  export default {
    name: 'QueuedLeads',
    components: {},
    data() {
      return {
        tableData: [],
        tableShowLoading: true,
      }
    },
    mounted() {
      axios.get('/lead/onlyQueued')
          .then(response => {
            let content = []
            response.data.forEach(value => {
              content.push(commons.flatObject(value))
            })
            this.tableData = content
          })
          .catch(err => console.error('Problem while fetching leads', err))
          .finally(() => this.tableShowLoading = false)
    },
    methods: {
      getTableFields() {
        return [
          'type',
          {key: 'holder.firstName', label: 'Vorname'},
          {key: 'holder.lastName', label: 'Nachname'},
          {key: 'holder.phoneNumber', label: 'Telefon'},
          {key: 'holder.email', label: 'E-Mail'},
          {key: 'values.address.formattedAddress', label: 'Adresse'},
          {key: 'created', label: 'Datum'},
          {
            key: 'show_details',
            label: '',
            _style: 'width:1%',
            sorter: false,
            filter: false
          }
        ]
      },
      getTableBadge(type) {
        switch (type) {
          case 'APARTMENT': return 'success';
          case 'HOUSE': return 'secondary';
          case 'LAND': return 'warning';
          case 'APARTMENT_HOUSE': return 'danger';
          case 'RENT_APARTMENT': return 'info';
          case 'RENT_HOUSE': return 'info';
          default: return 'primary';
        }
      }
    }
  }
</script>
